#feature-section-2 {
  margin-top: 185px;
}

#body-line-3 {
  height: 125px;
  width: 3px;
  top: 365px;
  right: 230px;
}

#body-line-box-3 {
  height: 70px;
  border-top: 3px solid #9e7509;
  border-left: 3px solid #9e7509;
  top: 490px;
  left: 180px;
  right: 230px;
}

#fs2-split-row {
  justify-content: space-between;
  align-items: center;
  padding-left: 120px;
  display: flex;
}

#fs2-split-row .feature-title, #fs2-split-row .feature-description {
  max-width: 500px;
}

#fs2-mobile-img {
  display: none;
}

@media (max-width: 1140px) {
  #feature-section-2 {
    margin-top: 50px;
  }

  #body-line-3, #body-line-box-3 {
    display: none;
  }

  #fs2-split-row {
    padding: 0 20px;
  }

  #fs2-split-row div:first-of-type {
    display: none;
  }

  #fs2-mobile-img {
    justify-content: center;
    margin-top: 20px;
    display: flex;
  }
}

/*# sourceMappingURL=index.cd962cfd.css.map */
