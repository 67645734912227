@import "constants";

#feature-section-2 {
  margin-top: 185px;
}

$body-line-3-height: 125px;
$body-line-3-top: 365px;

#body-line-3 {
  right: $body-line-right;
  height: $body-line-3-height;
  width: $body-line-size;
  top: $body-line-3-top;
}

#body-line-box-3 {
  border-top: $body-line-size solid $gold;
  border-left: $body-line-size solid $gold;
  top: calc(#{$body-line-3-height} + #{$body-line-3-top});
  left: $body-line-left;
  right: $body-line-right;
  height: 70px;
}

#fs2-split-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 120px;

  .feature-title {
    max-width: 500px;
  }

  .feature-description {
    max-width: 500px;
  }
}

#fs2-mobile-img {
  display: none;
}

@media (max-width: $mobile-screen-width) {
  #feature-section-2 {
    margin-top: 50px;
  }

  #body-line-3,
  #body-line-box-3 {
    display: none;
  }

  #fs2-split-row {
    padding: 0 $mobile-content-horizontal-padding;

    div:first-of-type {
      display: none;
    }
  }

  #fs2-mobile-img {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
